<template>
  <v-dialog v-model="dialog" fullscreen persistent transition="dialog-bottom-transition">
    <v-toolbar flat dark color="primary">
      <!-- close btn -->
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <!-- title -->
      <v-toolbar-title>Configurar Integrator - {{ getSafe(() => integrator_type.toUpperCase()) }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-card flat>
      <v-container>
        <v-expansion-panels v-model="panel" class="mt-3" focusable>
          <!-- driver -->
          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header>
              <span class="card-title">Driver</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- database, refresh_view_database -->
              <div v-if="drivers.length && (integrator_type === 'database' || integrator_type === 'refresh_view_database')" class="driver-container">
                <v-select v-model="computedImportConfiguration.driver" label="Drivers" :items="drivers" outlined />
              </div>
              <div v-else>
                <v-card-title>Não há opções disponíveis para esse Integrator</v-card-title>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- sqls -->
          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header>
              <span class="card-title">SQLs</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- database, refresh_view_database -->
              <div
                v-if="integrator_type === 'database' || integrator_type === 'refresh_view_database' || integrator_type === 'webservice_ciss'"
                class="sql-container"
              >
                <v-row>
                  <v-col cols="6">
                    <v-btn :disabled="!new_sql" color="primary" @click="addSql(new_sql, 'sqls')">Adicionar Query</v-btn>
                    <v-text-field v-model="new_sql" label="query_name" outlined class="mt-2" />
                  </v-col>
                </v-row>
                <div v-for="(value, key) in computedImportConfiguration.sqls" :key="key" class="sql-item">
                  <v-layout class="mb-3">
                    <span class="title key-title">{{ key }} </span>
                    <v-spacer />
                    <v-icon style="cursor: pointer; user-select: none" @click="deleteSql(key, 'sqls')">delete</v-icon>
                  </v-layout>
                  <sql-builder :sql="value" />
                </div>
              </div>
              <div v-else>
                <v-card-title>Não há opções disponíveis para esse Integrator</v-card-title>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- views -->
          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header>
              <span class="card-title">Views</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="integrator_type === 'refresh_view_database'" class="view-container">
                <v-row>
                  <v-col cols="6">
                    <v-btn :disabled="!new_view" color="primary" @click="addSql(new_view, 'views')">Adicionar View</v-btn>
                    <v-text-field v-model="new_view" label="view_name" outlined class="mt-2" />
                  </v-col>
                </v-row>
                <div v-for="(value, key) in computedImportConfiguration.views" :key="key" class="sql-item">
                  <v-layout class="mb-3">
                    <span class="title key-title">{{ key }} </span>
                    <v-spacer />
                    <v-icon style="cursor: pointer; user-select: none" @click="deleteSql(key, 'views')">delete</v-icon>
                  </v-layout>
                  <sql-builder :sql="value" />
                </div>
              </div>
              <div v-else>
                <v-card-title>Não há opções disponíveis para esse Integrator</v-card-title>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- command -->
          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header>
              <span class="card-title">Commands</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="integrator_type === 'ssh'" class="command-container">
                <v-row>
                  <v-col cols="6">
                    <v-btn :disabled="!new_command" color="primary" @click="addSh(new_command, 'commands')">Adicionar Command</v-btn>
                    <v-text-field v-model="new_command" label="command_name" outlined class="mt-2" />
                  </v-col>
                </v-row>
                <div v-for="(value, key) in computedImportConfiguration.commands" :key="key" class="sh-item">
                  <v-layout class="mb-3">
                    <span class="title key-title">{{ key }} </span>
                    <v-spacer />
                    <v-icon style="cursor: pointer; user-select: none" @click="deleteSh(key, 'commands')">delete</v-icon>
                  </v-layout>
                  <sh-builder :commands="value" :editable="true" />
                </div>
              </div>
              <div v-else>
                <v-card-title>Não há opções disponíveis para esse Integrator</v-card-title>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- output -->
          <v-expansion-panel class="mt-3">
            <v-expansion-panel-header>
              <span class="card-title">Output</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="integrator_type === 'ssh'" class="output-container">
                <v-text-field v-model="computedImportConfiguration.output.file_path" outlined label="file_path" />
                <v-text-field v-model="computedImportConfiguration.output.file_extension" outlined label="file_extension" />
              </div>
              <div v-else>
                <v-card-title>Não há opções disponíveis para esse Integrator</v-card-title>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    SqlBuilder: () => import('@/components/base/SqlBuilder.vue'),
    ShBuilder: () => import('@/components/base/ShBuilder.vue')
  },
  props: {
    dialog: Boolean,
    import_configuration: Object,
    drivers: Array,
    integrator_type: String
  },
  data: () => ({
    panel: [],
    new_sql: null,
    new_view: null,
    new_command: null,
    new_output: null
  }),
  computed: {
    computedImportConfiguration: {
      get() {
        return this.import_configuration
      },
      set(value) {
        this.$emit('update:import_configuration', value)
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    addSql(value, module) {
      this.$set(this.computedImportConfiguration[module], value, { query: '', atemporal: true })
      this.new_sql = null
      this.new_view = null
      this.$snackbar({ message: `Nova ${module === 'sqls' ? 'query  ' : 'view'} "${value}" adicionada` })
    },
    deleteSql(value, module) {
      this.$delete(this.computedImportConfiguration[module], value)
      this.$snackbar({ message: `${module === 'sqls' ? 'Query  ' : 'View'} deletada: "${value}"` })
    },
    addSh(value, module) {
      this.$set(this.computedImportConfiguration[module], value, { command: '', atemporal: true })
      this.new_command = null
      this.new_output = null
      this.$snackbar({ message: `Novo ${module === 'commands' ? 'command' : 'output'} "${value}" adicionado` })
    },
    deleteSh(value, module) {
      this.$delete(this.computedImportConfiguration[module], value)
      this.$snackbar({ message: `${module === 'commands' ? 'Command' : 'Output'} "${value}" deletado: "${value}"` })
    }
  }
}
</script>
<style lang="scss" scoped>
.driver-container {
  margin: 15px auto;
}

.sql-container,
.view-container {
  padding: 10px;

  .sql-item {
    margin: 25px auto;
  }
}

.output-container {
  margin: 15px auto;
}
</style>
